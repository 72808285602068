import React from "react";

import { AFFILIATE_ACCESS_TYPE } from "constants/affiliate.constants";
import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

export const getTableColumns = additionalProps => {

    const {
        isAffiliate,
        formatAmount,
        formatCurrencyWithSymbol,
        currencyCode,
        hasAccess,
        t,
        isAffiliateUserNameClickable,
        onAffiliateClick 
    } = additionalProps

    return [
        hasAccess(AFFILIATE_ACCESS_TYPE.PLAYER_ID) && {
            title: "backoffice.reports.playerId",
            dataIndex: "id",
            mobileLevel: 1,
            alwaysVisible: true,
            copy: true,
            fixed: "left"
        },
        hasAccess(AFFILIATE_ACCESS_TYPE.PLAYER_USERNAME) && {
            title: "backoffice.reports.playerUsername",
            dataIndex: "userName",
            mobileLevel: 2,
            alwaysVisible: true,
            fixed: "left"
        },
        {
            title: "backoffice.reports.registrationDate",
            dataIndex: "registrationDate",
            isDateTime: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.reports.country",
            dataIndex: "country",
            render: value => value ? t(`backoffice.countries.${value}`) : "-",
        },
        !isAffiliate && {
            title: "backoffice.reports.affiliateId",
            dataIndex: "affiliateId",
            copy: true
        },
        !isAffiliate && {
            title: "backoffice.reports.affiliateUsername",
            dataIndex: "affiliateUserName",
            render: (value, record) => {
                if (isAffiliateUserNameClickable) {
                    return (
                        <span className="rt--link" onClick={() => onAffiliateClick(record)}>{value}</span>
                    )
                }

                return value;
            }
        },

        {
            title: "backoffice.reports.brand",
            dataIndex: "brandName",
        },

        {
            title: "backoffice.reports.campaign",
            dataIndex: "campaignName",
        },

        {
            title: "backoffice.reports.mediaName",
            dataIndex: "mediaName"
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositAmount")),
            dataIndex: "depositAmount",
            render: value => formatAmount(value, currencyCode),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT) && {
            title: "backoffice.reports.withdrawCount",
            dataIndex: "withdrawCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.withdrawAmount")),
            dataIndex: "withdrawAmount",
            render: value => formatAmount(value, currencyCode),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ftdAmount")),
            dataIndex: "ftdAmount",
            render: value => formatAmount(value, currencyCode),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) && {
            title: "backoffice.reports.qftd",
            dataIndex: "isCPACalculated",
            render: value => value ? t("backoffice.common.yes") : t("backoffice.common.no"),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.netDeposit")),
            dataIndex: "netDepositAmount",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.sportBetCount",
            dataIndex: "sportBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportBet")),
            dataIndex: "sportBetAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportWonAmount")),
            dataIndex: "sportWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportGGR")),
            dataIndex: "sportGGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportBonusWonAmount")),
            dataIndex: "sportBonusWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportAdminFee")),
            dataIndex: "sportFeeAmount",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <span>{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportNGR"))}</span>
                    <Tooltip
                        title={t('backoffice.reports.sportNGRTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "sportNGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.casinoBetCount",
            dataIndex: "casinoBetCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoBet")),
            dataIndex: "casinoBetAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoWonAmount")),
            dataIndex: "casinoWonAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoGGR")),
            dataIndex: "casinoGGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoBonusWonAmount")),
            dataIndex: "casinoBonusWonAmount",
            render: value => formatAmount(value, currencyCode)
        },
        
        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoAdminFee")),
            dataIndex: "casinoFeeAmount",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <span>{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoNGR"))}</span>
                    <Tooltip
                        title={t('backoffice.reports.casinoNGRTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "casinoNGR",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <span>{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ngr"))}</span>
                    <Tooltip
                        title={t('backoffice.reports.ngrTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "ngr",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositFee")),
            dataIndex: "depositFeeAmount",
            render: value => formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADJUSTMENTS) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.adjustments")),
            dataIndex: "adjustmentsAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <b className="rt--title rt--font-normal rt--font-regular">{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportRevShare"))}</b>
                    <Tooltip
                        title={t('backoffice.reports.sportRevShareTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "sportRevShareAmount",
            render: value => formatAmount(value, currencyCode),
            mobileLevel: 4
        },

        {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <b className="rt--title rt--font-normal rt--font-regular">{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoRevShare"))}</b>
                    <Tooltip
                        title={t('backoffice.reports.casinoRevShareTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "casinoRevShareAmount",
            render: value => formatAmount(value, currencyCode),
            mobileLevel: 5
        },

        {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <span>{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalRevShareAmount"))}</span>
                    <Tooltip
                        title={t('backoffice.reports.totalRevShareAmountTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "totalRevShareAmount",
            render: value => formatAmount(value, currencyCode),
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.cpaAmount")),
            dataIndex: "cpaAmount",
            render: value => formatAmount(value, currencyCode),
            mobileLevel: 6
        },

        {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <span>{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalEarning"))}</span>
                    <Tooltip
                        title={t('backoffice.reports.totalEarningTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "totalCommissionAmount",
            render: value => formatAmount(value, currencyCode)
        },
    ]
}