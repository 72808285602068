import React, { forwardRef } from "react";

import Form from "..";

import AutoComplete from "core/ui-kit/autoComplete";

import classNames from "core/helpers/common/classNames";

const FormAutocomplete = forwardRef(({
    options=[],
	name,
	label,
	rules=[],
	className,
	showIsRequired = true,
	noStyle,
	placeholder,
    dependencies,
    disabled,
    normalize,
    ...props
}, ref) => {

	const isRequired = rules && rules.find(rule => rule.required) && showIsRequired;

	const builtLabel = label ? isRequired ? `${label} *` : label : "";

    const builtClassName = classNames(
        disabled && "rt--form-item-disabled",
        className
    )

	return (
		<Form.Item
			className={builtClassName}
			name={name}
			rules={rules}
            dependencies={dependencies}
			label={builtLabel}
			noStyle={noStyle}
            validateFirst={rules.length > 1}
            normalize={normalize}
		>
			<AutoComplete
                ref={ref} 
                placeholder={placeholder}
                items={options}
                disabled={disabled}
                {...props}
            />
		</Form.Item>
	);
});

export default FormAutocomplete;
