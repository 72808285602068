import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { AutoComplete as AntAutoComplete, Spin } from 'antd';

import { debounce } from "utils/common"

import autoSuggestionType from 'types/autoSuggestion/autoSuggestion.type';

/** AutoComplete Component */
const AutoComplete = ({
    placeholder,
    items,
    value,
    onChange,
    onSelect,
    changeHandler,
    disableFiltering=false,
    loading,
    showId,
    valueMapper = (_, value) => value,
    valueGetter = (_, value) => value,
    ...props
}) => {

    const [search, setSearch] = useState("");

    /** Get Filtered Options
       * @function
       * @returns {array} 
       * @description filters items by search value, and sort them according to search value position on string
       * @memberOf AutoComplete
   */
    const options = useMemo(() => {

        if(loading){
            return [];
        }

        if(disableFiltering){
            return items.map(i => ({value: i.name}));
        }

        const shouldInclude = item => {
            if(!item) return false;
            const findValue = search.toLowerCase();
            const idValue = item.id?.toString()?.toLowerCase() ?? "";
            const longIdValue = item.longId?.toString()?.toLowerCase() ?? "";
            const nameValue = item.name?.toString()?.toLowerCase() ?? "";
            return (
                idValue === findValue || 
                longIdValue === findValue ||
                nameValue.includes(findValue)
            )
        }

        const filtered = search ? items.filter(shouldInclude): [];
        
        filtered.sort((a, b) => {
            let aIndex = 1000000; // too big number
            let bIndex = 1000000;
            const findValue = search.toLowerCase();
            const aNameValue = a.name?.toString()?.toLowerCase() ?? "";
            const bNameValue = b.name?.toString()?.toLowerCase() ?? "";

            if (aNameValue.includes(findValue)) {
                aIndex = aNameValue.indexOf(findValue);
            }
            if (bNameValue.includes(findValue)) {
                bIndex = bNameValue.indexOf(findValue);
            }

            return aIndex >= bIndex ? 1 : -1;
        });

        return filtered.map(f => ({value: !showId ? f.name : `${f.name}(ID=${f.longId})`}));
    }, [loading, disableFiltering, items, search, showId])

    const mappedValue = useMemo(() => {
        if (valueMapper) {
            return valueMapper(items, value);
        }

        return value;
    }, [valueMapper, value, items])

    const changeHandlerDebounced = useMemo(() => {
        return changeHandler ? debounce(changeHandler, 1000) : null;
    }, [changeHandler])

    const handleSelect = value => {
        const searchedItem = value !== "" ? items.find(item => (
            item.longId?.toString() === value ||
            item.name?.toLowerCase() === value.toLowerCase()
        )) || null : null;

        onSelect?.(valueGetter(items, value), searchedItem);
        onChange?.(valueGetter(items, value));
    }

    const handleChange = value => {
        onChange?.(valueGetter(items, value));
    }

    return (
        <AntAutoComplete
            placeholder={placeholder}
            options={options}
            onSearch= {v => {
                setSearch(v);
                changeHandlerDebounced?.(v);
            }}
            value={mappedValue}
            onChange={handleChange}
            onSelect={handleSelect}
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
            notFoundContent={ loading ? (
                <div className='rt--flex rt--align-center rt--justify-center' style={{height: "52px"}}>
                    <Spin />
                </div>
            ) : undefined }
            { ...props }
        />
    )
}

/** AutoComplete propTypes
    * PropTypes
*/
AutoComplete.propTypes = {
    /** Autocomplete data */
    items: PropTypes.arrayOf(autoSuggestionType),
    /** Placeholder of input */
    placeholder: PropTypes.string,
    /** Called when select an option or input value change, or value of input is changed */
    onChange: PropTypes.func,
    /** Called when select an option  */
    onSelect: PropTypes.func,
    /** Call function on input change */
    changeHandler: PropTypes.func,
    /** If true the filtering will not work */
    disableFiltering: PropTypes.bool,
    /** Show Id, in text */
    showId: PropTypes.bool
}

export default AutoComplete