import { lazy } from "react";

import Spin from "core/ui-kit/spin";

import withSuspenseHoc from "core/HOCs/withSuspense";

import ROUTES from "core/constants/routes/routes";

const PlayersContainer = withSuspenseHoc(lazy(() => import("./playersContainer")), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PlayersPageRoute = {
	path: ROUTES.PLAYERS,
	element: PlayersContainer, 
    permission: { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW },
    isProjectRequired: true,
};

export default PlayersPageRoute;
