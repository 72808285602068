import React from 'react';

import Icon from 'components/common/icon';
import Tooltip from 'components/common/tooltip';

import { MEDIA_TYPE } from 'constants/campaign.constants';
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

export const getTableColumns = additionalProps => {
    const {
        isAffiliate,
        trafficSources,
        formatNumber,
        formatAmount, 
        formatCurrencyWithSymbol,
        currencyCode,
        hasAccess,
        t,
        isAffiliateUserNameClickable,
        onAffiliateClick
    } = additionalProps;

    const mapping = trafficSources.reduce((res, current) => {
        res[current.longId] = current.name;
        return res;
    }, {});

    return [
        {
            title: "backoffice.reports.mediaId",
            dataIndex: "id",
            copy: true,
            alwaysVisible: true,
            fixed: "left"
        },
        {
            title: "backoffice.reports.mediaName",
            dataIndex: "name",
            sorter: true,
            mobileLevel: 1,
            alwaysVisible: true,
            fixed: "left"
        },
        {
            title: "backoffice.reports.mediaType",
            dataIndex: "type",
            tooltip: false,
            sorter: true,
            render: (mediaType) => {
                return (
                    <div className='rt--flex rt--align-center'>
                        <div className={'rt--media-type ' + (mediaType === MEDIA_TYPE.LINK ? "rt--media-type-link" : "rt--media-type-creative")}>
                            {
                                mediaType === MEDIA_TYPE.LINK
                                    ? <Icon name='direct-link' size={18} />
                                    : <Icon name='picture' size={18} />
                            }
                        </div>
                        <span>
                            {
                                mediaType === MEDIA_TYPE.LINK
                                    ? t("backoffice.campaigns.directLink")
                                    : t("backoffice.campaigns.creative")
                            }
                        </span>
                    </div>

                )
            }
        },
        {
            title: "backoffice.reports.brand",
            dataIndex: "brandName",
            sorter: true,
            mobileLevel: 2
        },
        {
            title: "backoffice.reports.campaign",
            dataIndex: "campaignName",
            sorter: true,
            mobileLevel: isAffiliate ? 3 : undefined
        },
        {
            title: "backoffice.reports.product",
            dataIndex: "product",
            render: value => value === PROJECT_BRAND_PRODUCT_TYPE.CASINO ?
                t(`backoffice.projects.casino`) : value === PROJECT_BRAND_PRODUCT_TYPE.SPORT ?
                    t(`backoffice.projects.sport`) : value === PROJECT_BRAND_PRODUCT_TYPE.OTHER ?
                        t(`backoffice.projects.other`) : "",
            sorter: true
        },
        {
            title: "backoffice.reports.language",
            dataIndex: "languageCode",
            render: value => value ? t(`backoffice.languages.${value.toLowerCase()}`) : "-",
            sorter: true
        },
        !isAffiliate && {
            title: "backoffice.reports.affiliateUsername",
            dataIndex: "affiliateUserName",
            sorter: true,
            mobileLevel: !isAffiliate ? 3 : undefined,
            render: (value, record) => {
                if (isAffiliateUserNameClickable) {
                    return (
                        <span className="rt--link" onClick={() => onAffiliateClick(record)}>{value}</span>
                    )
                }

                return value;
            }
        },
        !isAffiliate && {
            title: "backoffice.reports.affiliateId",
            dataIndex: "affiliateId",
            copy: true
        },
        isAffiliate && {
            title: "backoffice.reports.trafficSource",
            dataIndex: "trafficSources",
            multi: true,
            multiMapper: sources => sources ? sources.map(longId => mapping[longId]) : []
        },
        {
            title: "backoffice.reports.views",
            dataIndex: "viewCount",
            exportIndex: "totalViewCount",
            sorter: true,
            mobileLevel: 4,
            render: (value, record) => record.type === MEDIA_TYPE.LINK ? "-" : formatNumber(value)
        },
        {
            title: "backoffice.reports.validClicks",
            dataIndex: "validClickCount",
            exportIndex: "totalValidClickCount",
            isNumeric: true,
            sorter: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.reports.invalidClicks",
            dataIndex: "invalidClickCount",
            exportIndex: "totalInvalidClickCount",
            isNumeric: true,
            sorter: true,
            mobileLevel: 6
        },
        {
            title: "backoffice.reports.playerRegistrations",
            dataIndex: "playerRegistrationCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositAmount")),
            dataIndex: "depositAmount",
            render: value=> formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT) && {
            title: "backoffice.reports.withdrawCount",
            dataIndex: "withdrawCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.withdrawAmount")),
            dataIndex: "withdrawAmount",
            render: value=> formatAmount(value, currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.netDeposit")),
            dataIndex: "netDepositAmount",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ggr")),
            dataIndex: "ggr",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ngr")),
            dataIndex: "ngr",
            render: value => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, currencyCode)}
                </span>
            ),
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportRevShare")),
            dataIndex: "sportRevShareAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoRevShare")),
            dataIndex: "casinoRevShareAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.cpaAmount")),
            dataIndex: "cpaAmount",
            render: value => formatAmount(value, currencyCode)
        },

        {
            title: () => (
                <div className='rt--flex rt--align-center'>
                    <span>{formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalEarning"))}</span>
                    <Tooltip
                        title={t('backoffice.reports.totalEarningTooltip')}
                        trigger={["hover", "click"]}
                        placement="top"
                        enableMobile={true}
                    >
                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                            <Icon name="info" size={16} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: "totalCommissionAmount",
            render: value => formatAmount(value, currencyCode)
        },
    ]
}

export const getExpandTableColumns = additionalProps => {

    const {
        formatAmount,
        hasAccess
    } = additionalProps;

    return [
        {
            title: "backoffice.reports.currency",
            dataIndex: "currencyCode",
        },

        {
            title: "backoffice.reports.playerRegistrations",
            dataIndex: "playerRegistrationCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) && {
            title: "backoffice.reports.depositAmount",
            dataIndex: "depositAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT) && {
            title: "backoffice.reports.withdrawCount",
            dataIndex: "withdrawCount",
            isNumeric: true
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT) && {
            title: "backoffice.reports.withdrawAmount",
            dataIndex: "withdrawAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: "backoffice.reports.netDepositAmount",
            dataIndex: "netDepositAmount",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: "backoffice.reports.ggr",
            dataIndex: "ggr",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: "backoffice.reports.ngr",
            dataIndex: "ngr",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },

        {
            title: "backoffice.reports.sportRevShare",
            dataIndex: "sportRevShareAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.casinoRevShare",
            dataIndex: "casinoRevShareAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.cpaAmount",
            dataIndex: "cpaAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },

        {
            title: "backoffice.reports.totalEarning",
            dataIndex: "totalCommissionAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
    ]
}