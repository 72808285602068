import { Form as AntForm } from "antd";
import PropTypes from "prop-types";

import FormInput from "./input";
import FormPassword from "./password";
import FormUrl from "./url";
import FormSelect from "./select";
import FormCheckbox from "./checkbox";
import FormSwitch from "./switch";
import FormNumber from "./number";
import FormTextarea from "./textarea";
import FormAutocomplete from "./autocomplete";

const Form = ({
	children,
	layout = "vertical",
	isLabelColonVisible = false,
	isRequiredMarkVisible = false,
	...restProps
}) => {
	return (
		<AntForm
			layout={layout}
			colon={isLabelColonVisible}
			requiredMark={isRequiredMarkVisible}
			{...restProps}
		>
			{children}
		</AntForm>
	);
};

Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.useForm = AntForm.useForm;
Form.Input = FormInput;
Form.Password = FormPassword;
Form.Url = FormUrl;
Form.Select = FormSelect;
Form.Number = FormNumber;
Form.Textarea = FormTextarea;
Form.Checkbox = FormCheckbox;
Form.Switch = FormSwitch;
Form.Autocomplete = FormAutocomplete;

Form.propTypes = {
	children: PropTypes.node,
	layout: PropTypes.string,
	isLabelColonVisible: PropTypes.bool,
	isRequiredMarkVisible: PropTypes.bool,
};

export default Form;
