import React, { Fragment, useEffect, useMemo, useState } from "react"

import Checkbox from "core/ui-kit/checkbox";
import Radio from "core/ui-kit/radio";
import Spin from "core/ui-kit/spin";
import Modal from "core/ui-kit/modal";

import EmptyView from "../empty";
import TableActions from "./actions";
import TableDetails from "./details";

import { getMobileColumns } from "../helpers";

import classNames from "core/helpers/common/classNames";
import isFunction from "core/helpers/typeChecks/isFunction";

import useInfiniteScroll from "../hooks/useInfiniteScroll";

const TableMobile = ({
    loading=false,
    columns=[],
    data=[],
    onRow: onRowProps,
    rowSelection,
    rowClassName,
    expandable,
    onPagination=Function.prototype,
    pagination,
    rowKey="id",
    renderEmpty=() => <EmptyView />,
    actions,
    mobile={}
}) => {

    const onRow = Object.assign(
        { 
            click : {
                disabled: () => true, 
                handler: () => null
            }
        }, onRowProps
    );

    const [ detailsData, setDetailsData ] = useState({
        isVisible: false
    })

    const { elementRef: _infiniteScrollRef, scrollableElementRef: _infiniteScrollableRef} = useInfiniteScroll({
        callback: () => { 
            if(pagination.page * pagination.limit >= pagination.total){
                return;
            }
            onPagination({
                page: pagination.page + 1,
                limit: pagination.limit
            }) 
        },
        dependencies: [onPagination, pagination],
        disabled: !Boolean(pagination) || pagination.page * pagination.limit >= pagination.total,
    })

    useEffect(() => {
        if(!_infiniteScrollableRef.current) return;
        if(Boolean(pagination) && pagination.page === 1){
            _infiniteScrollableRef.current.scrollTo(0, 0)
        }
    }, [pagination])

    const mobileColumns = useMemo(() => {
        return getMobileColumns(columns)
    }, [columns]);

    const handleRowClick = (record, rowIndex, e) => {
        e.preventDefault();
        e.stopPropagation();

        if(onRow.click.disabled(record, rowIndex)){
            return;
        }
        onRow.click.handler(record, rowIndex)
    }

    const renderRowSelection = record => {
        if (rowSelection.type === "radio") {
            return (
                <div>
                    <Radio
                        value={record[rowKey]}
                    />
                </div>
            )
        } else if (rowSelection.type === "checkbox") {
            return (
                <div>
                    <Checkbox
                        value={record[rowKey]}
                    />
                </div>
            )
        }   
        
        return null;
    }

    const tableRowClassName = classNames(
        "rt--table-item rt--pb-4 rt--mb-12",
        rowClassName
    )

    const showFullViewData = mobileColumns.length < columns.length;

    const renderValue = (column, record) => {
        if(isFunction(column.render)){
            return column.render(record[column["dataIndex"]], record)
        }

        return record[column["dataIndex"]]
    }

    const showDetailsData = record => {
        setDetailsData({
            isVisible: true,
            record
        })
    }

    const hideDetailsData = () => {
        setDetailsData({
            isVisible: false
        })
    }

    const renderTableContent = () => {
        return (
            <Fragment>
                <Spin spinning={loading}>
                    <div className="rt--table-wrapper" ref={_infiniteScrollRef}>
                        <div className="rt--table">
                            {
                                data.map( (record, index) => (
                                    <div
                                        key={record[rowKey]}
                                        onClick={e => handleRowClick(record, index, e)}
                                        className={tableRowClassName}
                                    >
                                        <div className="rt--table-item-inner rt--flex">
                                            { 
                                                rowSelection && (
                                                    <div className='rt--table-item-chooser'>
                                                        { renderRowSelection(record) }
                                                    </div>
                                                ) 
                                            }
                                            {
                                                isFunction(mobile.rowPrefix) && mobile.rowPrefix(record) 
                                            }    

                                            <div className="rt--table-item-row rt--flex-equal rt--flex">
                                                {
                                                    mobileColumns.map(column => (
                                                        <div
                                                            key={column.dataIndex}
                                                            className={
                                                                classNames(
                                                                    "rt--table-item-col rt--flex rt--flex-col rt--justify-start rt--mb-8",
                                                                    column.className
                                                                )
                                                            }
                                                        >
                                                            {
                                                                column.title && (
                                                                    <b className="rt--title rt--font-normal rt--font-regular rt--pb-4">
                                                                        {
                                                                            isFunction(column.title) ? column.title() : column.title
                                                                        }
                                                                    </b>
                                                                )
                                                            }
                                                            <div className="rt--flex rt--align-center rt--table-item-col-inner">
                                                                <span className="rt--title rt--font-normal rt--font-bold">
                                                                    { renderValue(column, record) }
                                                                </span>
                                                            </div>
                                                            
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            {
                                                ( Boolean(actions) || showFullViewData ) && (
                                                    <TableActions 
                                                        actions={actions?.buttons ?? []}
                                                        record={record}
                                                        expandable={expandable}  
                                                        showFullViewData={showFullViewData}
                                                        showDetailsData={showDetailsData}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Spin>
            </Fragment>
        )
    }

    const renderTable = () => {

        const tableContent = renderTableContent();

        if(!rowSelection) return tableContent;

        switch (rowSelection?.type) {
            case "radio":
                return (
                    <Radio.Group
                        style={{ width: "100%" }}
                    >
                        { tableContent }
                    </Radio.Group>
                )
            case ROW_CHOOSER_TYPES.CHECKBOX:
                return (
                    <Checkbox.Group
                        style={{ width: "100%" }}
                    >
                        { tableContent }
                    </Checkbox.Group>
                )
            default:
                return tableContent;
        }
    }

    if(!loading && data.length === 0){
        return renderEmpty();
    }

    return (
        <Fragment>
            { renderTable() }

            <TableDetails 
                visible={detailsData.isVisible}
                onCancel={hideDetailsData}
                record={detailsData.record}
                expandable={expandable}
                showFullViewData={showFullViewData}
                columns={columns}
            />
        </Fragment>
    )
}

export default TableMobile;