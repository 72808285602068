import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

const affiliateService = {
    getAll(data, signal){
        return httpService.get({
            url: API_URL.GET_AFFILIATES,
            signal,
            queryData: { ...data }
        }).then(({ data: { value } }) => {
            return {
                data: value.item2,
                total: value.item1
            };
        })
    },

    forceLogout(data, signal){
        return httpService.post({
            url: API_URL.FORCE_LOGOUT_AFFILIATE,
            signal,
            requestBody: { ...data }
        }).then(({ data }) => {
            return data;
        })
    },

    createAffiliate(data, signal){
        return httpService.post({
            url: API_URL.CREATE_AFFILIATE,
            signal,
            requestBody: { ...data }
        }).then(({ data }) => {
            return data;
        })
    },

    depositToEarningBalance(data, signal){
        return httpService.post({
            url: API_URL.AFFILIATE_DEPOSIT_TO_EARNING_BALANCE,
            signal,
            requestBody: { ...data }
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    withdrawFromEarningBalance(data, signal){
        return httpService.post({
            url: API_URL.AFFILIATE_WITHDRAW_FROM_EARNING_BALANCE,
            signal,
            requestBody: { ...data }
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getAffiliateFees(id, signal){
        return httpService.get({
            url: API_URL.GET_AFFILIATE_FEES,
            signal,
            queryData: { id }
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    saveAffiliateFees(data, signal){
        return httpService.post({
            url: API_URL.SAVE_AFFILIATE_FEES,
            signal,
            requestBody: data
        }).then(({ data: { value, message } }) => {
            return { value, message};
        })
    },

    getAffiliateGeneralInfo(userNameOrId, signal){
        return httpService.get({
            url: API_URL.GET_AFFILIATE_GENERAL_INFO,
            signal,
            queryData: { userNameOrId }
        }).then(({ data: { value } }) => {
            return value;
        })
    }
}

export default affiliateService