export const filterAndSortItems = (items, searchValue = "") => {
    const search = searchValue.toLowerCase().trim();
    if (!search) {
        return [];
    }

    const comparator = item => {
        if(!item) return false;
        const searchToLower = search.toLowerCase();
        const idValue = item.id?.toString()?.toLowerCase() ?? "";
        const longIdValue = item.longId?.toString()?.toLowerCase() ?? "";
        const nameValue = item.name?.toString()?.toLowerCase() ?? "";
        return (
            idValue === searchToLower || 
            longIdValue === searchToLower ||
            nameValue.includes(searchToLower)
        )
    }

    const filtered = items.filter(item => comparator(item) );

    filtered.sort((a, b) => {
        let aIndex = 1000000; // too big number
        let bIndex = 1000000;
        const searchToLower = search.toLowerCase();
        const aText = a.name?.toString()?.toLowerCase() ?? "";
        const bText = b.name?.toString()?.toLowerCase() ?? "";

        if (aText.includes(searchToLower)) {
            aIndex = aText.indexOf(searchToLower);
        }
        if (bText.includes(searchToLower)) {
            bIndex = bText.indexOf(searchToLower);
        }

        return aIndex >= bIndex ? 1 : -1;
    });

    return filtered;
}

export const debounce = (func, wait = 1000, { before, after }={}) => {
    let timeoutId;

    return (...args) => {
        const context = this;
        if(before){
            before.apply(context, args);
        }
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            if(after){
                after.apply(context, args);
            }
            func.apply(context, args);
        }, wait);
    };
};