import ROUTES from 'core/constants/routes/routes';
import { USER_ROLE } from 'core/constants/auth';
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

import isPostbackEnabled from 'core/helpers/postback/isPostbackEnabled';
import isPostbackLogsEnabled from 'core/helpers/postback/isPostbackLogsEnabled';

const items = [
    { 
        key: '1',
        icon: 'dashboard', 
        title: 'backoffice.menu.dashboard',
        items: [
            {
                key: '11',
                title: 'backoffice.menu.operational',
                url: ROUTES.DASHBOARD_OPERATIONAL,
                permission: { resource: PERMISSION_RESOURCE.DASHBOARD_OPERATIONAL, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.DASHBOARD_OPERATIONAL],
                isProjectRequired: true
            },
            {
                key: '12',
                title: 'backoffice.menu.financial',
                url: ROUTES.DASHBOARD_FINANCIAL,
                permission: { resource: PERMISSION_RESOURCE.DASHBOARD_FINANCIAL, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.DASHBOARD_FINANCIAL],
                isProjectRequired: true
            },
            {
                key: '13',
                title: 'backoffice.menu.marketing',
                url: ROUTES.DASHBOARD_MARKETING,
                permission: { resource: PERMISSION_RESOURCE.DASHBOARD_MARKETING, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.DASHBOARD_MARKETING],
                isProjectRequired: true
            }     
        ],
    },
    {
        key: '2',
        icon: 'companies',
        title: 'backoffice.menu.companies',
        url: ROUTES.COMPANIES,
        permission: { resource: PERMISSION_RESOURCE.COMPANY, action: PERMISSION_ACTION.VIEW },
        match: [ROUTES.COMPANIES, ROUTES.COMPANIES_EDIT + "/:id"],
    },
    {
        key: '3',
        icon: 'globe',
        title: 'backoffice.menu.projects',
        url: ROUTES.PROJECTS,
        permission: { resource: PERMISSION_RESOURCE.PROJECT, action: PERMISSION_ACTION.VIEW },
        match: [ROUTES.PROJECTS, ROUTES.PROJECTS_EDIT + "/:id"],
    },
    {
        key: '4',
        icon: 'affiliate',
        title: 'backoffice.menu.myAccount',
        permission: { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
        isProjectRequired: true,
        userRole: [USER_ROLE.AFFILIATE],
        url: ROUTES.MY_ACCOUNT,
        match: [ROUTES.MY_ACCOUNT],
    },
    {
        key: '5',
        icon: 'affiliate',
        title: 'backoffice.menu.affiliates',
        permission: { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
        items: [
            {
                key: '51',
                title: 'backoffice.menu.accounts',
                url: ROUTES.AFFILIATES,
                permission: { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.AFFILIATES, ROUTES.AFFILIATES_EDIT + "/:id"],
                isProjectRequired: true,
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            },
            {
                key: '52',
                title: 'backoffice.menu.affiliateGroups',
                url: ROUTES.AFFILIATE_GROUPS,
                permission: { resource: PERMISSION_RESOURCE.AFFILIATE_GROUPS, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.AFFILIATE_GROUPS, ROUTES.AFFILIATE_GROUPS_EDIT + "/:id"],
                isProjectRequired: true
            },
            {
                key: '53',
                title: 'backoffice.menu.affiliateAccess',
                url: ROUTES.AFFILIATE_ACCESS,
                permission: { resource: PERMISSION_RESOURCE.PROJECT_AFFILIATE_ACCESS_SETTINGS, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.AFFILIATE_ACCESS],
                isProjectRequired: true
            }
        ]
    },
    {
        key: '6',
        icon: 'player',
        title: 'backoffice.menu.players',
        items: [
            {
                key: '61',
                title: 'backoffice.menu.list',
                url: ROUTES.PLAYERS,
                permission: { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.PLAYERS],
                isProjectRequired: true
            },
            {
                key: '62',
                title: 'backoffice.menu.btagChangeHistory',
                url: ROUTES.PLAYERS_BTAG_CHANGE_HISTORY,
                permission: { resource: PERMISSION_RESOURCE.PLAYER_BTAG_CHANGE_HISTORY, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.PLAYERS_BTAG_CHANGE_HISTORY],
                isProjectRequired: true
            }
        ]
    },
    {
        key: '7',
        icon: 'mediatools',
        title: 'backoffice.menu.marketingTools',
        isProjectRequired: true,
        items: [
            {
                key: '71',
                title: 'backoffice.menu.campaigns',
                url: ROUTES.CAMPAIGNS,
                permission: { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.CAMPAIGNS, ROUTES.CAMPAIGNS_EDIT + "/:id"],
                isProjectRequired: true
            },
            {
                key: '72',
                title: 'backoffice.menu.mediaTools',
                url: ROUTES.MEDIA_TOOLS,
                permission: { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.MEDIA_TOOLS],
                isProjectRequired: true
            }
        ]
    },
    {
        key: '8',
        icon: 'payments',
        title: 'backoffice.menu.payment',
        items: [
            {
                key: '81',
                title: 'backoffice.menu.approveCommission',
                url: ROUTES.CALCULATION_APPROVALS,
                match: [ROUTES.CALCULATION_APPROVALS],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            },
            {
                key: '82',
                title: 'backoffice.menu.invoices',
                url: ROUTES.INVOICES,
                match: [ROUTES.INVOICES],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_INVOICES, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            },
            {
                key: '83',
                title: 'backoffice.menu.balanceHistory',
                url: ROUTES.BALANCE_HISTORY,
                match: [ROUTES.BALANCE_HISTORY],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            }
        ]
    },
    {
        key: '9',
        icon: 'report',
        title: 'backoffice.menu.reports',
        items: [
            {
                key: '91',
                title: 'backoffice.menu.commissionCalculations',
                url: ROUTES.COMMISSION_CALCULATIONS,
                match: [ROUTES.COMMISSION_CALCULATIONS],
                permission: { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            },
            {
                key: '92',
                title: 'backoffice.menu.marketing',
                url: ROUTES.MARKETING_REPORTS,
                match: [ROUTES.MARKETING_REPORTS],
                permission: [
                    { resource: PERMISSION_RESOURCE.REPORT_CLICKS, action: PERMISSION_ACTION.VIEW },
                    { resource: PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
                ],
                isProjectRequired: true,
            },
            {
                key: '93',
                title: 'backoffice.menu.performance',
                url: ROUTES.PERFORMANCE_REPORTS,
                match: [ROUTES.PERFORMANCE_REPORTS],
                permission: [
                    { resource: PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
                    { resource: PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
                ],
                isProjectRequired: true,
            },
            {
                key: '94',
                title: 'backoffice.menu.aggregatedData',
                url: ROUTES.AGGREGATED_DATA,
                match: [ROUTES.AGGREGATED_DATA],
                permission: { resource: PERMISSION_RESOURCE.REPORT_AGGREGATION_HISTORY, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
            }
        ]
    },
    {
        key: 'a',
        icon: 'users',
        title: 'backoffice.menu.userManagement',
        items: [
            {
                key: 'a1',
                title: 'backoffice.menu.admins',
                url: ROUTES.USERS,
                permission: { resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.USERS, ROUTES.USERS_EDIT + "/:id"],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                key: 'a2',
                title: 'backoffice.menu.affiliateManagers',
                url: ROUTES.AFFILIATE_MANAGERS,
                permission: { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.AFFILIATE_MANAGERS, ROUTES.AFFILIATE_MANAGERS_EDIT + "/:id"],
                isProjectRequired: true
            },
            {
                key: 'a3',
                title: 'backoffice.menu.accessManagers',
                url: ROUTES.ACCESS_MANAGERS,
                permission: { resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.ACCESS_MANAGERS, ROUTES.ACCESS_MANAGERS_EDIT + "/:id"],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                key: 'a4',
                title: 'backoffice.menu.permissionGroups',
                url: ROUTES.PERMISSION_GROUPS,
                match: [ROUTES.PERMISSION_GROUPS, ROUTES.PERMISSION_GROUPS_EDIT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                key: 'a5',
                title: 'backoffice.menu.permissions',
                url: ROUTES.PERMISSIONS,
                match: [ROUTES.PERMISSIONS],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'a6',
                title: 'backoffice.menu.requests',
                url: ROUTES.PERMISSION_REQUESTS,
                match: [ROUTES.PERMISSION_REQUESTS],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            }
        ]
    },
    {
        key: 'b',
        icon: 'communication',
        title: 'backoffice.menu.communications',
        items: [
            {
                key: 'b1',
                title: 'backoffice.menu.systemNotifications',
                url: ROUTES.SYSTEM_NOTIFICATIONS,
                permission: { resource: PERMISSION_RESOURCE.COMMUNICATION, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.SYSTEM_NOTIFICATIONS, ROUTES.SYSTEM_NOTIFICATIONS_EDIT + "/:id"],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            },
            {
                key: 'b2',
                title: 'backoffice.menu.newsletter',
                url: ROUTES.NEWSLETTER,
                permission: { resource: PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.NEWSLETTER],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
            }
        ]
    },
    {
        key: 'c',
        icon: 'postback',
        title: 'backoffice.menu.postback',
        items: [
            {
                key: 'c1',
                title: 'backoffice.menu.list',
                url: ROUTES.POSTBACKS,
                permission: { resource: PERMISSION_RESOURCE.POSTBACK, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.POSTBACKS, ROUTES.POSTBACKS_EDIT + "/:id"],
                isProjectRequired: true,
                disabled: ( projectId, user, userInfo ) => !isPostbackEnabled(projectId, user, userInfo)
            },
            {
                key: 'c2',
                title: 'backoffice.menu.logs',
                url: ROUTES.POSTBACK_LOGS,
                permission: { resource: PERMISSION_RESOURCE.POSTBACK_LOGS, action: PERMISSION_ACTION.VIEW },
                match: [ROUTES.POSTBACK_LOGS],
                isProjectRequired: true,
                disabled: ( projectId, user, userInfo ) => !isPostbackLogsEnabled(projectId, user, userInfo)
            }
        ]
    },
    {
        key: 'd',
        icon: 'userlogs',
        title: 'backoffice.menu.userLogs',
        url: ROUTES.USER_LOGS,
        permission: { resource: PERMISSION_RESOURCE.USER_LOGS, action: PERMISSION_ACTION.VIEW },
        match: [ROUTES.USER_LOGS],
        userRole: [USER_ROLE.ADMIN]
    },
    {
        key: 'e',
        icon: 'cms',
        title: 'backoffice.menu.cms',
        items: [
            {
                key: 'e1',
                title: 'backoffice.menu.promoTranslations',
                url: ROUTES.TRANSLATIONS_PROMO,
                match: [ROUTES.TRANSLATIONS_PROMO],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'e2',
                title: 'backoffice.menu.boTranslations',
                url: ROUTES.TRANSLATIONS_BO,
                match: [ROUTES.TRANSLATIONS_BO],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    },
    {
        key: 'f',
        icon: 'settings',
        title: 'backoffice.menu.settings',
        items: [
            {
                key: 'f1',
                title: 'backoffice.menu.currencies',
                url: ROUTES.SETTINGS_CURRENCIES,
                match: [ROUTES.SETTINGS_CURRENCIES],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'f2',
                title: 'backoffice.menu.languages',
                url: ROUTES.SETTINGS_LANGUAGES,
                match: [ROUTES.SETTINGS_LANGUAGES],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_LANGUAGES, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'f3',
                title: 'backoffice.menu.payments',
                url: ROUTES.SETTINGS_PAYMENTS,
                match: [ROUTES.SETTINGS_PAYMENTS],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    },
    {
        key: 'g',
        icon: 'code',
        title: 'backoffice.menu.developerSpace',
        items: [
            {
                key: 'g1',
                title: 'backoffice.menu.errors',
                url: ROUTES.DEVELOPER_ERRORS,
                match: [ROUTES.DEVELOPER_ERRORS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'g2',
                title: 'backoffice.menu.integrationRequests',
                url: ROUTES.DEVELOPER_REQUESTS,
                match: [ROUTES.DEVELOPER_REQUESTS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'g3',
                title: 'backoffice.menu.jobs',
                url: ROUTES.DEVELOPER_JOBS,
                match: [ROUTES.DEVELOPER_JOBS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'g4',
                title: 'backoffice.menu.serviceMonitoring',
                url: ROUTES.DEVELOPER_MONITORING,
                match: [ROUTES.DEVELOPER_MONITORING],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            // {
            //     key: 'g5',
            //     title: 'backoffice.menu.platformTest',
            //     url: ROUTES.DEVELOPER_PLATFORM_TEST,
            //     match: [ROUTES.DEVELOPER_PLATFORM_TEST],
            //     permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
            //     userRole: [USER_ROLE.ADMIN]
            // },
            {
                key: 'fg',
                title: 'backoffice.menu.generations',
                url: ROUTES.DEVELOPER_GENERATIONS,
                match: [ROUTES.DEVELOPER_GENERATIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'g7',
                title: 'backoffice.menu.postDeploymentActions',
                url: ROUTES.DEVELOPER_POST_DEPLOYMENT_ACTIONS,
                match: [ROUTES.DEVELOPER_POST_DEPLOYMENT_ACTIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.MODIFY },
                userRole: [USER_ROLE.ADMIN]
            },
            {
                key: 'g8',
                title: 'backoffice.menu.dbConnections',
                url: ROUTES.DEVELOPER_DB_CONNECTIONS,
                match: [ROUTES.DEVELOPER_DB_CONNECTIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    }
]

export default items;