import { lazy } from "react";

import Spin from "core/ui-kit/spin";

import withSuspenseHoc from "core/HOCs/withSuspense";

import ROUTES from "core/constants/routes/routes";

const PlayerBtagChangeHistoryContainer = withSuspenseHoc(lazy(() => import("./playerBtagChangeHistoryContainer")), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PlayerBtagChangeHistoryPageRoute = {
	path: ROUTES.PLAYERS_BTAG_CHANGE_HISTORY,
	element: PlayerBtagChangeHistoryContainer, 
    permission: { resource: PERMISSION_RESOURCE.PLAYER_BTAG_CHANGE_HISTORY, action: PERMISSION_ACTION.VIEW },
    isProjectRequired: true,
};

export default PlayerBtagChangeHistoryPageRoute;
