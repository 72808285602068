import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useTranslation } from "core/hooks/useTranslation";

import { Spin } from 'antd';

import Tabs from "components/common/tabs";

import GeneralInfoComponent from './sections/general';
import Finance from './sections/finance';
import SessionsComponent from './sections/sessions';
import SubAffiliates from './sections/subAffiliates';

import ROUTES from "core/constants/routes/routes";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { USER_ROLE } from 'constants/user.constants';

import useCurrentProject from 'hooks/useCurrentProject';

import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import affiliateService from 'core/services/apiServices/affiliateService';


/** Affiliate Edit Page Component */
const AffiliateEditComponent = () => {
    const { t } = useTranslation();

    const [ searchParams ] = useSearchParams();
    const routeParams = useParams();
    const navigate = useNavigate();

    const role = useAuthSelector(userRoleSelector);

    const currentProject = useCurrentProject();

    const items = [
        {
            title: t("backoffice.affiliates.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent/>
        },
        {
            title: t("backoffice.affiliates.finance"),
            permissions: [
                { resource: PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW },
                { resource: PERMISSION_RESOURCE.AFFILIATE_FEES, action: PERMISSION_ACTION.VIEW }
            ],
            component: <Finance />,
        },
        {
            title: t("backoffice.affiliates.subAffiliates"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW }],
            component: <SubAffiliates />,
            disabled: !currentProject.isReferralEnabled
        },
        {
            title: t("backoffice.affiliates.sessions"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE_SESSIONS, action: PERMISSION_ACTION.VIEW }],
            component: <SessionsComponent />
        }
    ]

    useEffect(() => {
        if(searchParams.get("fromUsername")){
            affiliateService.getAffiliateGeneralInfo(routeParams.id)
                .then(value => {
                    if(value){
                        navigate({
                            pathname: `${ROUTES.AFFILIATES_EDIT}/${value.id}`,
                            search: `name=${value.userName}&longId=${value.longId}&state=${value.state}`
                        }, { replace: true})
                    }
                })
        }
    }, [])

    if(searchParams.get("fromUsername")){
        return <Spin />
    }

    return (
        <Tabs 
            items={items}
            mainPage={{ title: role === USER_ROLE.AFFILIATE ? t('backoffice.menu.myAccount') :  t('backoffice.menu.affiliates'), path: ROUTES.AFFILIATES }}
        />
    )
}


export default AffiliateEditComponent;