//#region react
import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getMediaCode,
    getMediaTools,
    setMediaToolsFilters,
    setMediaToolsSorting,
    deactivateMedia,
    activateMedia
} from "store/actions/portal/marketingTools/mediaTools/mediaTools.actions";
import {
    getCreativeHTML
} from "store/actions/portal/marketingTools/campaigns/creatives.action";
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Modal from "components/common/modal";
import Lightbox from 'components/common/lightbox';
import Filters from "./filters.component";
import MediaToolCreateComponent from "./createMedia/mediaTool-create-edit.component";
import MediaCode from "./mediaCode";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useAutosuggestion from "hooks/useAutosuggestion";
import useFormat from "hooks/useFormat";
//#endregion

//#region utils
import { makeImagePath } from "utils/common";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { CREATIVE_TYPE, MEDIA_STATE, MEDIA_TYPE } from "constants/campaign.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";
import { MEDIA_TOOLS } from "constants/pageName.constants";
import { USER_ROLE } from "constants/user.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import mediaType from "types/campaign/media.type"
import sortingType from "types/common/sorting.type";
//#endregion

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from "core/stores/authStore";
import usePermissions from "core/hooks/usePermission";
import ROUTES from "core/constants/routes/routes";

const MediaToolsComponent = ({
    getMediaTools,
    mediaTools,
    isLoading,
    total,
    setMediaToolsSorting,
    setMediaToolsFilters,
    sorting,
    filters,
    htmls,
    getCreativeHTML,
    getMediaCode,
    deactivateMedia,
    activateMedia
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const { formatNumber } = useFormat()

    const [selectedMediaCode, setSelectedMediaCode] = useState(null);

    const [mediaCreateEditPopupData, setMediaCreateEditPopupData] = useState({
        isVisible: false,
        data: null
    });

    const isAffiliate = userRole === USER_ROLE.AFFILIATE;

    const [ trafficSources ] = isAffiliate ? useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.TRAFFIC_SOURCES
    }) : [ [] ];

    // State to show/hide thumbnail
    const [lightbox, setLightbox] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: MEDIA_TOOLS });



    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, PERMISSION_ACTION.CREATE );
    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, PERMISSION_ACTION.MODIFY );
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, PERMISSION_ACTION.EXPORT );
    const hasAffiliateGeneralInfoPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, PERMISSION_ACTION.VIEW );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setMediaToolsFilters({
            ...filters,
            nameOrId: value ? item ? item.id : value : ""
        })
    }

    const handleDateSearchChange = value => {
        setMediaToolsFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    const showMediaToolCreatePopup = () => {
        setMediaCreateEditPopupData({
            isVisible: true,
            data: null
        });
    }

    const showMediaEditPopup = record => {
        setMediaCreateEditPopupData({
            isVisible: true,
            data: {
                campaignId: record.campaignId,
                creativeId: record.creativeId,
                type: record.type,
                trafficSources: record.trafficSources,
                name: record.name,
                id: record.id,
                creativeData: record.type === MEDIA_TYPE.CREATIVE ? {
                    name: record.creativeName,
                    id: record.creativeId
                } : null
            }
        })
    }

    const hideMediaToolCreateEditPopup = () => {
        setMediaCreateEditPopupData({
            isVisible: false,
            data: null
        });
    }

    const hideLightbox = () => {
        setLightbox(null)
    }

    const handleThumbnailClick = useCallback((record) => {
        if (record.type === MEDIA_TYPE.LINK) {
            return;
        }
        const items = [{
            type: record.creativeType === CREATIVE_TYPE.IMAGE ? "image" : "iframe",
            name: record.name,
            src: makeImagePath(record.creativeFilePath),
            id: record.creativeId,
            html: htmls[record.creativeId],
            loadHTML: (data) => getCreativeHTML(data.id, data => {
                const items = [
                    {
                        type: "iframe",
                        name: record.name,
                        width: record.dimension?.split("x")?.[0],
                        height: record.dimension?.split("x")?.[1],
                        html: data
                    }
                ]
                setLightbox({ items })
            })
        }]

        setLightbox({ items })
    }, [])

    const handleAffiliateClick = useCallback(record => {
        navigate({
            pathname: `${ROUTES.AFFILIATES_EDIT}/${record.affiliateId}`,
            search: `name=${record.affiliateUserName}&longId=${record.affiliateLongId}`
        })
    }, [navigate])


    const hideMediaCodePopup = () => {
        setSelectedMediaCode(null)
    }

    const handleCloseTagIconClick = (record) => {
        getMediaCode(record.id, mediaCode => {
            setSelectedMediaCode(mediaCode);
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded is only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                onThumbnailClick: handleThumbnailClick,
                onAffiliateClick: handleAffiliateClick,
                isAffiliateUserNameClickable: hasAffiliateGeneralInfoPermission,
                isAffiliate,
                trafficSources,
                formatNumber,
                t
            },
            t
        });
    }, [includedColumns, handleThumbnailClick, isAffiliate, trafficSources, t, handleAffiliateClick, hasAffiliateGeneralInfoPermission])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    tableRowActions.push({
        title: t('backoffice.campaigns.getCode'),
        icon: "close-tag",
        onClick: handleCloseTagIconClick,
    });

    if(hasModifyPermission){
        
        tableRowActions.push({
            icon: "disable",
            title: t('backoffice.campaigns.disable'),
            confirmationTitle: t("backoffice.campaigns.disableMedia"),
            confirmationMessage: t("backoffice.campaigns.doYouWanttoDisableMedia"),
            showConfirmation: true,
            onClick: record => deactivateMedia(record.id),
            disabled: record => record.status === MEDIA_STATE.INACTIVE
        });

        tableRowActions.push({
            icon: "enable",
            title: t('backoffice.campaigns.enable'),
            confirmationTitle: t("backoffice.campaigns.enableMedia"),
            confirmationMessage: t("backoffice.campaigns.doYouWanttoEnableMedia"),
            showConfirmation: true,
            onClick: record => activateMedia(record.id),
            disabled: record => record.status === MEDIA_STATE.ACTIVE
        });

        tableRowActions.push({
            icon: "edit",
            title: t("backoffice.common.edit"),
            onClick: record => showMediaEditPopup(record)
        });
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getMediaTools,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.MEDIA,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.campaigns.nameOrId"),
            showId: true
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.creationDate'),
            showTime: false,
            loadFn: getMediaTools,
            value: [filters.from, filters.to]
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        buttons: [
            {
                icon: "plus",
                type: "primary",
                onClick: showMediaToolCreatePopup,
                text: t("backoffice.campaigns.createMedia"),
                enabled: hasCreatePermission,
            },
        ],
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.mediaTools') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.medias"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_MEDIAS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={mediaTools}
                loadFn={getMediaTools}
                sorting={sorting}
                setSortingFn={setMediaToolsSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />

            {
                mediaCreateEditPopupData.isVisible && (
                    <MediaToolCreateComponent
                        isEdit={Boolean(mediaCreateEditPopupData.data)}
                        formInitialValues={mediaCreateEditPopupData.data ? mediaCreateEditPopupData.data : undefined} 
                        onClose={hideMediaToolCreateEditPopup} 
                    />
                )
            }

            {lightbox && (
                <Lightbox
                    onClose={hideLightbox}
                    items={lightbox.items}
                />
            )}

            {selectedMediaCode && (
                <Modal
                    title={t("backoffice.campaigns.mediaCode")}
                    size={POPUP_SIZE.BIGER}
                    cancelText={t('backoffice.common.close')}
                    hideOkButton={true}
                    onCancel={hideMediaCodePopup}
                >
                    <MediaCode code={selectedMediaCode} />
                </Modal>
            )}
        </MainDashboardLayout>
    )
};

/** MediaToolsComponent propTypes
 * PropTypes
*/
MediaToolsComponent.propTypes = {
    /** Redux action to get media tools */
    getMediaTools: PropTypes.func,
    /** Redux state property, represents the array of media tools  */
    mediaTools: PropTypes.arrayOf(mediaType), //TODO: complete
    /** Redux state property, is true when loading media tools */
    isLoading: PropTypes.bool,
    /** Redux state property, media tools total count */
    total: PropTypes.number,
    /** Redux action to set media tools sorting details */
    setMediaToolsSorting: PropTypes.func,
    /** Redux action to set media tools sorting details */
    setMediaToolsFilters: PropTypes.func,
    /** Redux state property, media tools sorting details */
    sorting: sortingType,
    /** Redux state property, media tools filters */
    filters: PropTypes.object,
    /** Redux state property, Creative htmls */
    htmls: PropTypes.objectOf(PropTypes.string),
    /** Redux action to get creative html */
    getCreativeHTML: PropTypes.func,
    /** Redux action to get media code */
    getMediaCode: PropTypes.func,
    /** Redux action to activate media */
    activateMedia: PropTypes.func,
    /** Redux action to deactivate media */
    deactivateMedia: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    getMediaTools: nextPage => {
        dispatch(getMediaTools(nextPage));
    },
    setMediaToolsSorting: sorting => {
        dispatch(setMediaToolsSorting(sorting));
    },
    setMediaToolsFilters: filters => {
        dispatch(setMediaToolsFilters(filters));
    },
    getCreativeHTML: (id, onSuccess) => {
        dispatch(getCreativeHTML(id, onSuccess))
    },
    getMediaCode: (id, onSuccess) => {
        dispatch(getMediaCode(id, onSuccess))
    },
    activateMedia: id => {
        dispatch(activateMedia(id))
    },
    deactivateMedia: id => {
        dispatch(deactivateMedia(id))
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.mediaTools.isLoading,
        mediaTools: state.mediaTools.mediaTools,
        total: state.mediaTools.total,
        sorting: state.mediaTools.sorting,
        filters: state.mediaTools.filters,
        htmls: state.campaigns.edit.creatives.htmls,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaToolsComponent);
