import React, { useMemo, useState } from "react";

import { AutoComplete as AntAutoComplete } from 'antd';
import { filterAndSortItems, debounce } from "./helpers";

import Input from "core/ui-kit/input";

const AutoComplete = ({
    items=[],
    valueProp="name",
    renderText,
    disableFilter=false,
    onSearch=Function.prototype,
    debounceSearch=false,
    searchProps={},
    ...rest
}) => {

    const [search, setSearch] = useState("");

    const handleSearch = value => {
        setSearch(value);
        debouncedSearch(value);
    }

    const options = useMemo(() => {
        if(disableFilter){
            return items;
        }
        return filterAndSortItems(items, search).map(item => ({
            value: item[valueProp],
            label: renderText ? renderText(item) : item.name
        }))
    }, [items, search, disableFilter, renderText])

    const debouncedSearch = useMemo(() => {
        return debounceSearch ? debounce(onSearch, 1000) : onSearch;
    }, [onSearch, debounceSearch])
    
    return (
        <AntAutoComplete
            onSearch={handleSearch} 
            options={options}
            { ...rest }
        >
            <Input 
                {...searchProps}    
            />
        </AntAutoComplete>
    )
}

export default AutoComplete